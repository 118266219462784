import React from 'react'
import { Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'
import styles from './mobilemenu.module.css'

const stylesMenu = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '32px',
    top: '26px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '50px',
    width: '50px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    // display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

export default () => (
  <nav className={styles.mobileMenu} role="navigation">
    <Menu right styles={ stylesMenu }>
      <div className={styles.linkContainer}><Link to='/'>About Me</Link></div>
      <div className={styles.linkContainer}><Link to='/projects'>Projects</Link></div>
      <div className={styles.linkContainer}><a target='_blank' href='https://hushforms.com/yannstepienik'>Contact</a></div>
      <div className={styles.linkContainer}><a target='_blank' href='/CV.pdf'>CV</a></div>
      <div  className={styles.linkExt}>
        <span><a target='_blank' href='https://github.com/azukaar/'><img width="45px" src="/github.png" /></a></span>
        <span><a target='_blank' href='https://medium.com/@azukaar/'><img  width="45px" src="/medium.png" /></a></span>
        <span><a target='_blank' href='https://www.linkedin.com/in/yann-stepienik-b1200444/'><img  width="45px" src="/linkedin.png" /></a></span>
        <span><a target='_blank' href='https://twitter.com/stepienik/'><img  width="45px" src="/twitter.png" /></a></span>
      </div>
    </Menu>
  </nav>
)
