import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

export default () => (
  <nav className={styles.navigation} role="navigation">
    <div>Y.S.</div>
    <div className={styles.nomobile}>
      <span><Link to='/'>About Me</Link></span>
      <span><Link to='/projects'>Projects</Link></span>
      <span><a target='_blank' href='https://hushforms.com/yannstepienik'>Contact</a></span>
      <span><a target='_blank' href='/CV.pdf'>CV</a></span>
    </div>
    <div className={styles.nomobile}>
      <span><a target='_blank' href='https://github.com/azukaar/'><img width="45px" src="/github.png" /></a></span>
      <span><a target='_blank' href='https://medium.com/@azukaar/'><img  width="45px" src="/medium.png" /></a></span>
      <span><a target='_blank' href='https://www.linkedin.com/in/yann-stepienik-b1200444/'><img  width="45px" src="/linkedin.png" /></a></span>
      <span><a target='_blank' href='https://twitter.com/stepienik/'><img  width="45px" src="/twitter.png" /></a></span>
    </div>
  </nav>
)
